<template>
  <p>Dashboard works!</p>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Dashboard",
  components: {},
  setup() {
    return {};
  }
});
</script>

<style lang="scss" scoped></style>
